.hidden {
  display: none;
}

.nav-container {
  padding: 4px;
  background-color: black;
  font-family: Proxima Nova, helvetica neue, helvetica, arial, sans-serif;
}

input {
  margin-left: 10px;
  margin-right: 10px;
}

#nav-you {
  color: white;
}

/* Header */

#logo {
  width: 195px;
}

.logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
}


/* Profile Button */

.profile-button {
 background-color: unset;
 border: none;
 cursor: pointer;
}

.profile-dropdown {
  border: 1px solid black;
  border-radius: 15px;
  min-height: 90px;
  position: absolute;
  top: 25px;
  right: 38px;
  z-index: 3;
  padding-inline-start: 5px;
  background-color: #212124;
}

.profile-menu-container {
  padding: 0px;
}

.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  color: white;
}

#profile-btn-pic {
  width: 36px;
  padding-top: 4px;
}

.profile-container button {
  margin-bottom: 12px;
  margin-top: 5px;
  cursor: pointer;
  font-family: Proxima Nova, helvetica neue, helvetica, arial, sans-serif;
}

.profile-container button:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #ccc;
  cursor: pointer;
  border-radius: 4px;
}


nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 4px;
}

.nav-links  {
  display: flex;
  flex-direction: row;

}

.nav-links > div, .youbutton-button {
  cursor: pointer;
}

/* YouButton */
.youbutton-dropdown {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  width: 90px;
  height: 120px;
  position: absolute;
  left: 216px;
  z-index: 3;
  background-color: #212124;

}

.youbutton-button {
  position: relative;
  padding: 0px 0px;
  margin-right: 57px;
  margin-left: 41px;
}

.youbutton-container {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.youbutton-container div {
  padding-bottom: 8px;
  padding-top:10px;
  width: 100%;
  text-align: center;
  transition: background-color 0.3s;
}

.youbutton-container div:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #ccc;
  cursor: pointer;
}

#about-link:hover {
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

/* Tag Filter Icons */
#tag-icons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding-bottom: 28px;
  padding-top: 10px;
  background-color: black;
  font-family: Proxima Nova, helvetica neue, helvetica, arial, sans-serif;
}

.tag-icons {
  display: flex;
  flex-direction: column;
  align-items: center;;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-color: black;
  transition: all 0.15s ease-in-out;
}

.tag-icons:hover {
  transform: scale(1.1);
  text-shadow: 0px 0px 2px #ddd;
}

.nav-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: white;
}
