.hide-file-upload {
    display: none;
}

.style-file-upload {
    background-color: #5380fa;
    color: white;
    border: 1px black solid;
    border-radius: 5px;
    padding: 5px;
    box-shadow: 2px 2px black;
}

.style-file-upload:hover {
    cursor: pointer;
}

.div-file-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

/* Radio buttons */

.radio-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 10px;
    font-family: Proxima Nova, helvetica neue, helvetica, arial, sans-serif;
    width: 50%;
}

.radio-buttons input {
    display: flex;
    flex-direction: row;
    justify-self: flex-start;
    align-items: center;
    font-family: Proxima Nova, helvetica neue, helvetica, arial, sans-serif;
}

.radio-buttons label {
    font-family: Proxima Nova, helvetica neue, helvetica, arial, sans-serif;
}
