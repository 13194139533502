.standard-padding {
    padding: 20px;
}

.banner-parent-container > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-shadow: 2px 2px black;
    color: white;
    padding: 20px;
    padding-bottom: 56px;
    font-family: Proxima Nova, helvetica neue, helvetica, arial, sans-serif;
    background-image: url(https://shutterscape.s3.us-west-1.amazonaws.com/shutterscape/rainy-default-bg.jpg);
}

.banner-nav-container {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    padding: 8px 0px 0px 20px;
    font-size: 16px;
    color: #34343b;
    background-color: white;
    padding: 20px;
    border-bottom: 1px solid grey;
}

.banner-nav-container div {
    padding-right: 32px;
    cursor: pointer;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    font-family: Proxima Nova, helvetica neue, helvetica, arial, sans-serif;
}

.banner-nav-container div:hover {

    text-decoration: underline;
    color: black;
}

.allposts-parent-container button {
    margin: 10px;
    height: 33px;
    font-family: Proxima Nova, helvetica neue, helvetica, arial, sans-serif;
    border-radius: 12px;
    margin-bottom: 15px;
    cursor: pointer;
}

.banner-user-details {

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

}

.profile-photo {
    display: flex;
    border: 1px solid white;
    padding: 20px;
    border-radius: 50%;
    background-color: rgb(226, 43, 177);
    margin-right: 20px;
}

#banner-fname-lname {
    font-size: 25px;
    margin-bottom: 1px;
}

#banner-username {
    margin-top: 0px;
}

.banner-totalcount {
    font-size: 20px;
    margin-right: 20px;
}

/* can we get the camera to grow? */
.fa-camera {
    width: 100%;
}

.login-modal-input input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;

}
