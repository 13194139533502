.input-wrapper {
    background-color: white;
    width: 420px;
    border-radius: 4px;
    height: 25px;
    padding: 0 15px;
    box-shadow: 0px 0px 8px #ddd;
    display: flex;
    align-items: center;
    font-family: Proxima Nova, helvetica neue, helvetica, arial, sans-serif;
    margin-left: 30px;
}

.search-input {
    background-color: transparent;
    border: none;
    height: 100%;
    font-size: 1.25rem;
    width: 100%;
    margin-left: 5px;
    font-family: Proxima Nova, helvetica neue, helvetica, arial, sans-serif;
}

input:focus {
    outline: none;
    font-family: Proxima Nova, helvetica neue, helvetica, arial, sans-serif;
}

#search-icon {
    color: grey;
}

.results-list {
    position: absolute;
    font-size: 10pt;
    background-color: white;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 8px #ddd;
    border-radius: 10px;
    max-height: 185px;
    top: 42px;
    overflow-y: scroll;
    font-family: Proxima Nova, helvetica neue, helvetica, arial, sans-serif;
}

.search-result{
    padding: 10px 20px;
    font-family: Proxima Nova, helvetica neue, helvetica, arial, sans-serif;
    cursor:pointer;
}

.search-result:hover{
    background-color: #efefef;
}

.results-container{
    margin-left: 423px;

}

.no-results {
    padding: 10px 20px;
    font-family: Proxima Nova, helvetica neue, helvetica, arial, sans-serif;
}
