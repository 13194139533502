#modal {
   position: fixed;
   top: 0;
   right: 0;
   left: 0;
   bottom: 0;
   display: flex;
   justify-content: center;
   align-items: center;
}

#modal-background {
   position: fixed;
   top: 0;
   right: 0;
   left: 0;
   bottom: 0;
   background-color: rgba(0, 0, 0, 0.7);
}

#modal-content {
   position: absolute;
   background-color: #212124;
   border-radius: 10px;
   font-family: Proxima Nova, helvetica neue, helvetica, arial, sans-serif;
   color: white;
}

.modalButton {
   background-color: #5380fa;
   color: white;
   border: 2px black solid;
   border-radius: 5px;
}

